import { OrganisationActionTypes } from "./constants";
import { all, fork, takeEvery, put, takeLatest, call } from "redux-saga/effects";
import { getOrganisationEndPoint, createOrganisationEndPoint, updateOrganisationEndPoint, deleteOrganisationEndPoint } from "./api";
import { decryptPayload, encryptPayload } from "../../CryptoUtil";
import ToastHandle from "../../../helpers/ToastMessage";



function* getOrgination(action) {
    try {
        yield put({
            type: OrganisationActionTypes.GET_ORGANISATION_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getOrganisationEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: OrganisationActionTypes.GET_ORGANISATION_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: OrganisationActionTypes.GET_ORGANISATION_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: OrganisationActionTypes.GET_ORGANISATION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: OrganisationActionTypes.GET_ORGANISATION_ERROR,
            payload: error,
        });
    }
}

function* createOrgination(action) {
    try {
        yield put({
            type: OrganisationActionTypes.CREATE_ORGANISATION_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(createOrganisationEndPoint, action?.data);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: OrganisationActionTypes.CREATE_ORGANISATION_SUCCESS,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'success')
            } else {
                yield put({
                    type: OrganisationActionTypes.CREATE_ORGANISATION_ERROR,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'danger')

            }
        } else {
            yield put({
                type: OrganisationActionTypes.CREATE_ORGANISATION_ERROR,
                payload: { message: 'No response data' },
            });

        }
    } catch (error) {
        yield put({
            type: OrganisationActionTypes.CREATE_ORGANISATION_ERROR,
            payload: error,
        })
    }
};

function* updateOrgination(action) {
    try {
        yield put({
            type: OrganisationActionTypes.UPDATE_ORGANISATION_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(updateOrganisationEndPoint, action?.data);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'success')

            } else {
                yield put({
                    type: OrganisationActionTypes.UPDATE_ORGANISATION_ERROR,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'danger')
            }
        } else {
            yield put({
                type: OrganisationActionTypes.UPDATE_ORGANISATION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: OrganisationActionTypes.UPDATE_ORGANISATION_ERROR,
            payload: error,
        })
    }
};

function* deleteOrgination(action) {
    try {
        yield put({
            type: OrganisationActionTypes.DELETE_ORGANISATION_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(deleteOrganisationEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'success')
            } else {
                yield put({
                    type: OrganisationActionTypes.DELETE_ORGANISATION_ERROR,
                    payload: decryptedResponse,
                });
                ToastHandle(decryptedResponse?.message, 'danger')
            }
        } else {
            yield put({
                type: OrganisationActionTypes.DELETE_ORGANISATION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: OrganisationActionTypes.DELETE_ORGANISATION_ERROR,
            payload: error,
        })
    }
};

export function* watchOrganisation(): any {
    yield takeEvery(OrganisationActionTypes.GET_ORGANISATION_FIRST, getOrgination)
    yield takeEvery(OrganisationActionTypes.CREATE_ORGANISATION_FIRST, createOrgination)
    yield takeLatest(OrganisationActionTypes.UPDATE_ORGANISATION_FIRST, updateOrgination)
    yield takeEvery(OrganisationActionTypes.DELETE_ORGANISATION_FIRST, deleteOrgination)
}

function* orginationSaga(): any {
    yield all([
        fork(watchOrganisation),
    ]);
}

export default orginationSaga;