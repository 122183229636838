import { AdminNdSiteAdminPlanActionTypes } from './constants';

const GET_PLAN_TYPE_ADMIN_INITIAL_STATE = {
    getPlanType: [],
    loading: false,
};
const CREATE_PLAN_ADMIN_INITIAL_STATE = {
    createPlan: [],
    loading: false,
};
const GET_PLANS_ADMIN_INITIAL_STATE = {
    getPlans: [],
    loading: false,
    error: null
};
const GET_PLAN_SUBSCRIPTION_ADMIN_INITIAL_STATE = {
    getPlanSubscription: [],
    loading: false,
};

const VERIFY_OTP_ADMIN_INITIAL_STATE = {
    verifyOtp: [],
    loading: false,
};


const getPlanTypeReducer = (state = GET_PLAN_TYPE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getPlanType: state.getPlanType,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getPlanType: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getPlanType: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_PLAN_TYPE_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const createPlanReducer = (state = CREATE_PLAN_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                createPlan: state.createPlan,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                createPlan: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                createPlan: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return CREATE_PLAN_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const verifyOtpReducer = (state = VERIFY_OTP_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                verifyOtp: state.verifyOtp,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                verifyOtp: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                verifyOtp: {
                    status: false,
                    message: 'Invalid Otp !'
                },
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return {
                verifyOtp: [],
                loading: false,
            }

        default:
            return state;
    }
};

const getPlansReducer = (state = GET_PLANS_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getPlans: state.getPlans,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getPlans: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_PLANS_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const getPlanSubscriptionByIdReducer = (state = GET_PLAN_SUBSCRIPTION_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getPlanSubscription: state.getPlanSubscription,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getPlanSubscription: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getPlanSubscription: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_PLAN_SUBSCRIPTION_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};


export { getPlanTypeReducer, createPlanReducer, getPlansReducer, getPlanSubscriptionByIdReducer, verifyOtpReducer };
