import { AdminNdSiteAdminInstanceActionTypes } from './constants';

const GET_INSTANCE_ADMIN_INITIAL_STATE = {
    getInstanceAdmin: [],
    loading: false,
};

const ADD_INSTANCE_ADMIN_INITIAL_STATE = {
    addInstanceAdmin: [],
    loading: false,
};

const ADD_LICENCE_ADMIN_INITIAL_STATE = {
    addLicenceAdmin: [],
    loading: false,
};

const EDIT_INSTANCE_ADMIN_INITIAL_STATE = {
    addInstanceAdmin: [],
    loading: false,
};

const STATUS_INSTANCE_ADMIN_INITIAL_STATE = {
    statusInstanceAdmin: [],
    loading: false,
};
const DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    deleteInstanceAdmin: [],
    loading: false,
};

const ACTIVE_USER_ADMIN_INITIAL_STATE = {
    ActiveUserAdmin: [],
    loading: false,
};

const GET_INSTANCE_PLANS_INITIAL_STATE = {
    getPlans: [],
    loading: false,
};

const getInstanceAdminReducer = (state = GET_INSTANCE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getInstanceAdmin: state.getInstanceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getInstanceAdmin: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getInstancePlaneReducer = (state = GET_INSTANCE_PLANS_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_LOADING:
            return {
                getPlans: state.getPlans,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_SUCCESS:
            return {
                getPlans: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR:
            return {
                getPlans: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const addInstanceAdminReducer = (state = ADD_INSTANCE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addInstanceAdmin: state.addInstanceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
            return ADD_INSTANCE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const editInstanceAdminReducer = (state = EDIT_INSTANCE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addInstanceAdmin: state.addInstanceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
            return EDIT_INSTANCE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const statusInstanceAdminReducer = (state = STATUS_INSTANCE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusInstanceAdmin: state.statusInstanceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
            return STATUS_INSTANCE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};
const deleteInstanceAdminNdSiteAdminReducer = (state = DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                deleteInstanceAdmin: state.deleteInstanceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                deleteInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                deleteInstanceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
            return DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};
const addLicenceAdminReducer = (state = ADD_LICENCE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addLicenceAdmin: state.addLicenceAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addLicenceAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addLicenceAdmin: action.payload,
                loading: false,
            };
        // case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
        // return ACTIVE_USER_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};
const activeUserAdminReducer = (state = ACTIVE_USER_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                ActiveUserAdmin: state.ActiveUserAdmin,
                loading: true,
            };
        case AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                ActiveUserAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                ActiveUserAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminInstanceActionTypes.INSTANCE_STATE_RESET:
            return ACTIVE_USER_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getInstanceAdminReducer,
    addInstanceAdminReducer,
    editInstanceAdminReducer,
    statusInstanceAdminReducer,
    deleteInstanceAdminNdSiteAdminReducer,
    addLicenceAdminReducer,
    activeUserAdminReducer,
    getInstancePlaneReducer
};
