import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminInstanceActionTypes } from './constants';
import {
    adminNdSiteAdminGetInstanceEndPoint,
    adminNdSiteAdminAddInstanceEndPoint,
    adminNdSiteAdminEditInstanceEndPoint,
    adminNdSiteAdminStatusInstanceEndPoint,
    adminNdSiteAdminDeleteInstanceEndPoint,
    AddLicenceEndPoint,
    activeEndPoint,
    getInstancePlansEndPoint
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* callGetInstanceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminGetInstanceEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callGetInstancePlan(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getInstancePlansEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR,
            payload: error,
        });
    }
}

function* callAddInstanceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminAddInstanceEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditInstanceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminEditInstanceEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusInstanceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminStatusInstanceEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* calldeleteInstanceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.id);
        const response = yield call(adminNdSiteAdminDeleteInstanceEndPoint, action?.id);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callAddLicenceAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(AddLicenceEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        // yield put({
        //     type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET,
        //     payload: {},
        // });
    }
}
function* callACtiveAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(activeEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

// end cash day Report List

export function* getInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callGetInstanceAdminNdSiteAdmin
    );
}
export function* getInstanceAllPlan(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN,
        callGetInstancePlan
    );
}

export function* addInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callAddInstanceAdminNdSiteAdmin
    );
}
export function* addlicenceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN,
        callAddLicenceAdminNdSiteAdmin
    );
}
export function* addActiveAdminNdSiteAdminAction(): any {
    yield takeEvery(AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN, callACtiveAdminNdSiteAdmin);
}

export function* editInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callEditInstanceAdminNdSiteAdmin
    );
}

export function* statusInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callStatusInstanceAdminNdSiteAdmin
    );
}
export function* deleteInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN,
        calldeleteInstanceAdminNdSiteAdmin
    );
}

function* getInstanceAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getInstanceAdminNdSiteAdminAction),
        fork(addInstanceAdminNdSiteAdminAction),
        fork(editInstanceAdminNdSiteAdminAction),
        fork(statusInstanceAdminNdSiteAdminAction),
        fork(deleteInstanceAdminNdSiteAdminAction),
        fork(addlicenceAdminNdSiteAdminAction),
        fork(addActiveAdminNdSiteAdminAction),
        fork(getInstanceAllPlan),
    ]);
}

export default getInstanceAdminNdSiteAdminSaga;
